import { ErrorHandler, Injectable } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import {
    bufferTime,
    catchError,
    debounceTime,
    exhaustMap,
    filter,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { LogInterface } from './logging.interface';
import { LoggingService } from './logging.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    private readonly _data$: Subject<string> = new Subject();
    private dataRequest$: Subject<string[]> = new Subject();
    private data$: Observable<string> = this._data$.asObservable();

    constructor(private loggingService: LoggingService) {
        super();
        this.data$
            .pipe(
                exhaustMap(err =>
                    merge(of(err), this.data$).pipe(
                        bufferTime(10000),
                        takeUntil(merge(of(err), this.data$).pipe(debounceTime(10100))),
                    ),
                ),
            )
            .subscribe(data => this.dataRequest$.next(data));

        this.dataRequest$
            .pipe(
                filter((data: string[]) => !!data.length),
                switchMap((data: string[]) =>
                    this.loggingService.sentLogs(data).pipe(catchError(error => of({ error }))),
                ),
            )
            .subscribe();
    }

    handleError(error): void {
        if (window.location.host.split(':')[0] !== 'localhost') {
            const data: LogInterface = {
                messageError: error.message,
                stackError: error.stack,
            };
            this._data$.next(JSON.stringify(data));
        }

        super.handleError(error);
    }
}
