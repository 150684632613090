<ng-container>
    <ng-container>
        @if (isLazyLoading$ | async) {
            <mat-progress-bar mode="indeterminate" class="lazy-loader" />
        }
    </ng-container>
    <router-outlet></router-outlet>
    <ng-template #devNotification>
        <div class="tw-w-96 tw-text-xl tw-text-dark">
            <button
                (click)="devNotificationSnackBar?.dismiss()"
                class="tw-absolute tw-cursor-pointer tw-top-2 tw-right-2 tw-z-10 tw-border-0 tw-bg-white hover:tw-text-dark-light">
                ✕
            </button>
            <div class="tw-w-full tw-font-medium tw-mb-4">Версия для тестирования</div>
            <div class="tw-w-full">
                Данная версия предназначена для разработки и тестирования функционала приложения и не содержит
                реальные данные.
            </div>
        </div>
    </ng-template>
</ng-container>
