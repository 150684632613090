import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState } from '@core/auth/auth.state';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    private authState = inject(AuthState);

    constructor(private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkAuth();
    }

    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth();
    }

    checkAuth() {
        if (!this.authState.isAuth()) {
            this.router.navigate(['/login']);
        }

        return this.authState.isAuth();
    }
}

export const authGuard = () => {
    const authState = inject(AuthState);
    const router = inject(Router);
    if (!authState.isAuth()) {
        router.navigate(['/login']);
    }

    return authState.isAuth();
};
