import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateRangeComponent } from '../../fields/date-range/date-range.component';

@Component({
    selector: 'hml-mobility-date-range-type',
    templateUrl: './date-range-type.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DateRangeComponent, ReactiveFormsModule],
})
export class DateRangeTypeComponent extends FieldType {}
