import { ENVIRONMENT_COMMON } from './environment.common';

export const environment = {
    ...ENVIRONMENT_COMMON,
    configuration: 'stage',
    showDevBanner: true,
    production: true,
    login: {
        name: null,
        pass: null,
    },
    baseUrl: 'https://stageapi.mobility.hyundai.ru/',
    baseUrlFixed: 'https://stageapi.mobility.hyundai.ru/',
};
