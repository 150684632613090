import { RoleSet } from '@pages/mobility/settings/store/interfaces/user.interface';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AuthLoginForm, AuthResponseInterface } from './auth.interfaces';
import { Moment } from 'moment';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadMenuAction } from '@core/layout/components/sidenav/store/sidenav.actions';
import { ComponentStorePersistent } from '@shared/store/component-store-persistent/persistent.store';
import { Observable, of, switchMap } from 'rxjs';
import { checkRoles } from '@shared/utils/check-roles';
import { ROLE_ROOT, ROLE_DEV } from '@shared/models';

export interface AuthStateModel {
    _id: string;
    email: string;
    fio: string;
    last_id: number;
    phoneNum: number;
    roles: string[];
    access_token: string;
    expires_at: Moment;
    role_sets: RoleSet[];
    transport_park: string;
    department: string;
    isErrorAuth: boolean;
}

export class AuthStateData implements AuthStateModel {
    _id = null;
    email = null;
    last_id = null;
    phoneNum = null;
    roles = [];
    access_token = '';
    expires_at = null;
    fio = null;
    role_sets = [];
    transport_park = null;
    department = null;
    isErrorAuth = false;
}

@Injectable({
    providedIn: 'root',
})
export class AuthState extends ComponentStorePersistent<AuthStateData> {
    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private globalStore: Store,
    ) {
        super(new AuthStateData(), 'auth', ['isErrorAuth']);
    }

    public isAuth = this.selectSignal<boolean>(state => !!state.access_token && !!state.roles);
    public stateValues = this.selectSignal<AuthStateModel>(state => state);
    public accessToken = this.selectSignal<string>(state => state.access_token);
    public roles = this.selectSignal<string[]>(state => state.roles);
    public email = this.selectSignal<string>(state => state.email);
    public isErrorAuth = this.selectSignal<boolean>(state => state.isErrorAuth);
    public isHasRight = (roles: string[]) =>
        this.selectSignal<boolean>(state => checkRoles([ROLE_ROOT, ROLE_DEV, ...roles], state.roles));

    public authLogout() {
        this.setState(new AuthStateData());
        window.location.reload();
    }

    readonly switchToRoleSet = this.effect((origin$: Observable<RoleSet>) => {
        return origin$.pipe(
            switchMap(payload => {
                const user = this.get();
                return this.httpClient
                    .post(`/user/${user._id}`, {
                        ...user,
                        number: user.phoneNum?.toString(),
                        roles: payload.roles,
                    })
                    .pipe(
                        tap(update => this.patchState({ ...user, ...update })),
                        tap(() => window.location.reload()),
                    );
            }),
        );
    });

    readonly authLogin = this.effect((origin$: Observable<AuthLoginForm>) => {
        return origin$.pipe(
            switchMap(payload => {
                const url = '/auth/login';
                this.patchState({ isErrorAuth: false });
                return this.httpClient
                    .post(url, { username: payload.email, password: payload.password })
                    .pipe(
                        map((response: AuthResponseInterface) => {
                            const setState = {
                                ...response,
                                roles: response.roles,
                                access_token: response.access_token,
                                email: response.email,
                                phoneNum: response.number,
                                fio: response.fio,
                                last_id: response.last_id,
                            };
                            this.patchState({ ...setState });
                            this.router.navigate(['/']);
                        }),
                        catchError(err => {
                            this.patchState({ isErrorAuth: true });
                            return of(err);
                        }),
                    );
            }),
        );
    });

    readonly getProfileAction = this.effect((origin$: Observable<void>) => {
        return origin$.pipe(
            switchMap(() => {
                const url = '/auth/profile';
                return this.httpClient.get(url).pipe(
                    map((response: AuthResponseInterface) => {
                        const setState = {
                            ...response,
                            roles: response.roles,
                            phoneNum: response.number,
                            email: response.email,
                            fio: response.fio,
                            last_id: response.last_id,
                        };
                        this.patchState({ ...setState });
                        this.globalStore.dispatch(new LoadMenuAction(response.roles));
                        return response;
                    }),
                );
            }),
        );
    });
}
