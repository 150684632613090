import { Routes } from '@angular/router';
import { authGuard, AuthGuard } from '@shared/guards/auth.guard';
import { noAuthGuard } from '@core/auth/no-auth.guard';

export const APP_ROUTES: Routes = [
    {
        path: 'login',
        loadComponent: () =>
            import('./core/auth/auth-login/auth-login.component').then(c => c.AuthLoginComponent),
        canMatch: [noAuthGuard],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canMatch: [authGuard],
        loadChildren: () => import('./pages.routes').then(r => r.PAGES_ROUTES),
    },
    {
        path: '**',
        redirectTo: '/mobility',
    },
];
