import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EmbeddedViewRef,
    isDevMode,
    NgZone,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { AppInitActions } from '@core/store/app.state.actions';
import { UpdaterComponent } from '@shared/components/updater/updater.component';
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    Router,
    RouterEvent,
    RouterOutlet,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AsyncPipe } from '@angular/common';
import { environment } from '@environments/environment';

//
@Component({
    selector: 'hml-mobility-root',
    styles: ['.lazy-loader { position: absolute; z-index: 9999; }'],
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatProgressBarModule, AsyncPipe, RouterOutlet],
    providers: [MatSnackBar],
})
export class AppComponent implements OnInit, AfterViewInit {
    // eslint-disable-next-line @typescript-eslint/ban-types
    @ViewChild('devNotification', { static: false }) devNotification?: TemplateRef<{}>;
    devNotificationSnackBar: MatSnackBarRef<EmbeddedViewRef<any>>;

    public isLazyLoading$: Observable<boolean> = this.router.events.pipe(
        filter(
            event =>
                event instanceof NavigationStart ||
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel,
        ),
        map((event: Event | RouterEvent) => event instanceof NavigationStart),
    );

    constructor(
        private swUpdate: SwUpdate,
        private store: Store,
        private _snackBar: MatSnackBar,
        private router: Router,
        private ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this.swUpdate.versionUpdates
            .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
            .subscribe(v => {
                console.warn('SW update', v);
                this.openUpdateBar();
            });
        if (!isDevMode()) {
            this.ngZone.runOutsideAngular(() => {
                setInterval(() => {
                    this.swUpdate.checkForUpdate().then();
                }, 60000);
            });
        }
        this.store.dispatch(new AppInitActions());
    }

    ngAfterViewInit(): void {
        if (environment.showDevBanner) {
            this.devNotificationSnackBar = this._snackBar.openFromTemplate(this.devNotification, {
                panelClass: 'updateMessage',
                verticalPosition: 'bottom',
                horizontalPosition: 'end',
            });
        }
    }

    openUpdateBar(): void {
        this._snackBar.openFromComponent(UpdaterComponent, {
            panelClass: 'updateMessage',
            verticalPosition: 'top',
            horizontalPosition: 'start',
        });
    }
}
