import { ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { RootInjector } from '@shared/utils/services/root-injector';
import { environment } from '@environments/environment';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JoyrideModule } from 'ngx-joyride';
import { CodeEditorModule } from '@ngstack/code-editor';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ErrorHandlerService } from '@shared/services/loggingError/error-handler.service';
import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import {
    provideRouter,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { AppPreloadingStrategy } from '@app/app-preloading-strategy';
import { APP_ROUTES } from '@app/app.routes';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { APP_DATE_FORMAT } from '@shared/locale/app-date-format';
import { NgxsModule } from '@ngxs/store';
import { appHttpInterceptor } from '@app/app-http.interceptor';
import { metaResponseInterceptor } from '@shared/interceptors/meta-response-interceptor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NZ_DATE_CONFIG, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import nzDatePickerLocaleRuRU from '@shared/locale/nz-date-picker-locale.ru-RU';
import { FormlyModule } from '@ngx-formly/core';
import { DateRangeTypeComponent } from '@shared/components/formly-fields/date-range-type/date-range-type.component';
import { InputRangeTypeComponent } from '@shared/components/formly-fields/input-range-type/input-range-type.component';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { QuillModule } from 'ngx-quill';
// rebuild 1
registerLocaleData(ru);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            BrowserAnimationsModule,
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
            NgxsModule.forRoot([], {
                developmentMode: !environment.production,
            }),
            // NgxsStoragePluginModule.forRoot({
            //     key: ['auth'],
            // }),
            QuillModule.forRoot({
                placeholder: '',
                bounds: '.quill-container',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        ['link', 'image'],
                    ],
                },
            }),
            JoyrideModule.forRoot(),
            CodeEditorModule.forRoot({
                baseUrl: 'assets/monaco',
            }),
            FormlyModule.forRoot({
                types: [
                    {
                        name: 'date-range',
                        component: DateRangeTypeComponent,
                        wrappers: [],
                    },
                    {
                        name: 'input-range',
                        component: InputRangeTypeComponent,
                        wrappers: [],
                    },
                ],
            }),
            FormlyMaterialModule,
            FormlyMatDatepickerModule,
        ),
        MatSnackBar,
        provideHttpClient(withInterceptors([appHttpInterceptor, metaResponseInterceptor])),
        provideEnvironmentNgxMask(),
        provideRouter(
            APP_ROUTES,
            withPreloading(AppPreloadingStrategy),
            /*
             * Навигация начинается до создания корневого компонента (его создание блокируется).
             * Нужно для работы серверного рендеринга.
             * По умолчанию роутинг начинается после создания корневого компонента.
             * */
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({
                // Скролл при переходе на вверх страницы
                scrollPositionRestoration: 'top',
                /*
                 * Переход по анкору, если есть в адресной строке.
                 * По умолчанию не работает переход.
                 * */
                anchorScrolling: 'enabled',
            }),
        ),
        {
            provide: NZ_I18N,
            useValue: {
                ...ru_RU,
                DatePicker: nzDatePickerLocaleRuRU,
            },
        },
        {
            provide: NZ_DATE_CONFIG,
            useValue: {
                firstDayOfWeek: 1, // week starts on Monday (Sunday is 0)
            },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
            },
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        {
            provide: LOCALE_ID,
            useValue: 'ru',
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'ru',
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: APP_DATE_FORMAT,
        },
    ],
})
    .then(ngModuleRef => {
        if ('serviceWorker' in navigator && environment.production) {
            navigator.serviceWorker.register('/ngsw-worker.js');
        }
        RootInjector.setInjector(ngModuleRef.injector);
    })
    .catch(err => console.error(err));
