import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    HostListener,
    Input,
    OnDestroy,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { auditTime, combineLatest, merge, Subject, Subscription } from 'rxjs';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import { map, skip, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'hml-mobility-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateRangeComponent),
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatDatepickerModule, MatButtonModule, MatIconModule],
})
export class DateRangeComponent implements ControlValueAccessor, OnDestroy {
    private onTouched = () => {
        return;
    };
    public disabled: boolean;
    public form: UntypedFormGroup = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    });
    private subscribeForm: Subscription = null;

    reset$ = new Subject();
    dateStartChange = new Subject();
    dateEndChange = new Subject();
    dateRange$ = combineLatest([
        this.dateStartChange.pipe(startWith(null)),
        this.dateEndChange.pipe(startWith(null)),
    ]).pipe(
        /*
         * Пропускам дефолтные значения, которые нужны для активизации отслеживания потока.
         * Делаем это ввиду того, что дату конца периода могут не выбрать, а
         * только с одной датой combineLatest не испустит событие
         * */
        skip(1),
        // Дожидаемся, когда приходит событие end. Оно эмитится сразу, так что хватит и 0
        auditTime(0),
        map(([start, end]: [Date, Date]) => ({ start, end })),
    );

    @Input() to: FormlyTemplateOptions = {};

    @HostListener('blur') private doBlur(): void {
        this.onTouched();
    }

    registerOnChange(fn: () => NonNullable<unknown>): void {
        this.subscribeForm = merge(this.reset$, this.dateRange$).subscribe(fn);
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: object): void {
        if (value) {
            this.form.setValue(value);
        } else {
            this.form.reset();
        }
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    reset() {
        this.form.reset();
        this.reset$.next({ start: null, end: null });
    }

    ngOnDestroy(): void {
        this.subscribeForm && this.subscribeForm.unsubscribe();
    }
}
