import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    template: `
        <div class="flex align-center space-between">
            <span>Доступно обновление.</span>

            <div class="flex align-center">
                <button mat-stroked-button color="primary" (click)="update()">Обновить</button>
                <button mat-icon-button (click)="close()">
                    <mat-icon class="close-icon">close</mat-icon>
                </button>
            </div>
        </div>
    `,
    standalone: true,
    imports: [MatIconModule, MatButtonModule],
})
export class UpdaterComponent {
    constructor(private _snackBar: MatSnackBar) {}

    update() {
        console.log('AppUpdateAction');
        window.location.reload();
    }

    close() {
        this._snackBar._openedSnackBarRef.dismiss();
    }
}
