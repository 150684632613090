import { Component, forwardRef, HostListener, Input, OnDestroy } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    ReactiveFormsModule,
} from '@angular/forms';
import { FormlyTemplateOptions } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'hml-mobility-input-range',
    templateUrl: './input-range.component.html',
    styleUrls: ['./input-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputRangeComponent),
        },
    ],
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule],
})
export class InputRangeComponent implements ControlValueAccessor, OnDestroy {
    private onTouched = () => {
        return;
    };
    public disabled: boolean;
    public form: UntypedFormGroup = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    });
    public labelStart = 'От';
    public labelEnd = 'До';

    private subscribeForm: Subscription = null;

    private _to: FormlyTemplateOptions = {};
    @Input()
    set to(value: FormlyTemplateOptions) {
        this.setLabel(value?.label);
        this._to = value;
    }
    get to() {
        return this._to;
    }

    @HostListener('blur') private doBlur(): void {
        this.onTouched();
    }

    private setLabel(label: string): void {
        if (label) {
            const [labelStart, labelEnd] = label.split('|');
            this.labelStart = labelStart;
            this.labelEnd = labelEnd;
        }
    }

    registerOnChange(fn: () => NonNullable<unknown>): void {
        this.subscribeForm = this.form.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: object): void {
        if (value) {
            this.form.setValue(value);
        } else {
            this.form.reset();
        }
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnDestroy(): void {
        this.subscribeForm && this.subscribeForm.unsubscribe();
    }
}
