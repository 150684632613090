export const ENVIRONMENT_COMMON = {
    showDevBanner: false,
    sessions: {
        vehiclePreparationHoursFreedom: 0,
        vehiclePreparationHoursCountry: 0,
        changeSessionStartMaxDaysFreedom: 15,
        changeSessionStartMaxDaysCountry: 15,
        minStartSessionTimeHour: 8,
        maxStartSessionTimeHour: 21,
    },
    organization: {
        newBillsDefs: {
            count: '1', // string
            units: 'ед',
            priceWTax: true,
            tax: '20', // string
        },
    },
    refsI18nLang: [
        { lang: 'ru', descr: 'Русский' },
        { lang: 'en', descr: 'Английский' },
    ],
    // stompString: 'wss://crm.mobility.hyundai.ru/ws',
    CRM: true,
    // baseUrl: '/localApi/',
    // baseUrlFixed: 'https://devapi.mobility.hyundai.ru/',
};
