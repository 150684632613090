export enum AllSessionTypes {
    SessionCounty = 'country',
    SessionFreedom = 'freedom',
    SessionFreedomOrganization = 'freedom_organization',
    SessionFreedomFilter = 'freedom_filter',
    SessionFreedomTravel = 'freedom_travel',
}

const SESSIONS_FREEDOM_YEAR = [AllSessionTypes.SessionFreedom, AllSessionTypes.SessionFreedomOrganization];
const SESSIONS_FREEDOM_MONTH_PLUS = [
    AllSessionTypes.SessionFreedomFilter,
    AllSessionTypes.SessionFreedomTravel,
];
const SESSIONS_FREEDOM = [
    AllSessionTypes.SessionFreedom,
    AllSessionTypes.SessionFreedomOrganization,
    AllSessionTypes.SessionFreedomFilter,
    AllSessionTypes.SessionFreedomTravel,
];
const SESSIONS_COUNTRY = [AllSessionTypes.SessionCounty];
export const SESSION_TYPES = {
    freedom: SESSIONS_FREEDOM,
    freedom_year: SESSIONS_FREEDOM_YEAR,
    freedom_month_plus: SESSIONS_FREEDOM_MONTH_PLUS,
    country: SESSIONS_COUNTRY,
    all: [...SESSIONS_FREEDOM, ...SESSIONS_COUNTRY],
};
