import { Session, SessionOrder } from '../sessions';
import { VehicleItem } from '../vehicles';

export enum DebtsAlarmsStatuses {
    Warning = 'WARNING',
    Danger = 'DANGER',
    Info = 'INFO',
}

export interface DebtAlarm {
    count: number;
    status: 'WARNING_SERVICE_DEBT' | 'DANGER_SERVICE_DEBT' | DebtsAlarmsStatuses | string;
    isAnyActive?: boolean;
}

export interface DebtUnit {
    userFio?: string;
    userNum?: string;
    userEmail?: string;
    userId?: string;
    vehicleModelColor?: string;
    vehicleId?: string;
    sessionTypeDuration?: string;
    sessionStatusRef?: string;
    // debtTypeInfo: DebtGovFineInfo |

    refund?: boolean;
    _id: string;
    date_create: Date;
    reservationSessionsSize: string;
    type: string;
    creator: string;
    status: string;
    discount?: number;
    discountDate?: Date;
    discountDesc?: string;
    _id_session: string;
    _id_ucs: string;
    _id_order: string;
    order_id: string;
    date_pay: Date;
    user_id: number;
    vehicle_id: number;
    date_debt: Date;
    price: number;
    details: DebtDetails | any;

    _id_vehicle?: string;
    resolution_number?: string;
    photos?: string[];
    priceType?: string;
    customerName?: string;
    vehicleLicense?: string;
    price_percents?: number;
    savedPhotos?: string[];
    orders: SessionOrder[];
    sessionData: DebtSessionRef;
    user: {
        _id: string;
    };
    typeRu: string;
    statusRu: string;
    description: string;
    realization: boolean;
    expectedBlockDate?: string | null;
    dateDecisionFormat: string;
    comments: DebtComment[];
    alarms: DebtAlarm[];
    debtId?: string;
}

export interface DebtSessionRef extends Session {
    vehicleRef: VehicleItem;
    timeZone: number;
}

export interface DebtComment {
    comment: string;
    date: string | Date;
}

export interface DebtDetails {
    payed: {
        start: string;
        end: string;
        mileage: number;
        payed: number;
    };
    days?: {
        start: string;
        end: string;
        days: number;
        debt: number;
        refund?: number;
    };
    mileage?: {
        start: number;
        end: number;
        debt: number;
    };
    gasoline?: {
        start: number;
        end: number;
        debt: number;
        refund: number;
    };
    total: {
        debt: number;
        refund: number;
    };
    _id_sessions: string[];
    dateMail?: string; // Дата и время проезд
    mailNum?: string; // Номер проезда
    route?: string; // Маршрут
}
