import { catchError, EMPTY, MonoTypeOperatorFunction, Observable, tap } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RootInjector } from '@shared/utils/services/root-injector';

const configSnackBar: MatSnackBarConfig = {
    duration: 6000,
    verticalPosition: 'bottom',
    horizontalPosition: 'end',
};

export const configSuccessSnackBar: MatSnackBarConfig = {
    ...configSnackBar,
    panelClass: 'okMessage',
};

export const configWarnSnackBar: MatSnackBarConfig = {
    ...configSnackBar,
    panelClass: 'warnMessage',
};

export const actionTextSnackBar = '✕';

export const messageSuccessDefault = 'Запрос успешно выполнен';
export const messageWarnDefault = 'Операция не выполнена, попробуйте еще раз';

export class SnackBarError {
    public error = {
        meta: {
            message: '',
        },
    };
    constructor(public message: string) {
        this.error.meta.message = message;
    }
}

export const snackBarSuccess = <T>(message?: string): MonoTypeOperatorFunction<T> => {
    return <T>(source: Observable<T>): Observable<T> => {
        return source.pipe(
            tap(() => {
                const snackBar = RootInjector.get(MatSnackBar);
                snackBar.open(message || messageSuccessDefault, actionTextSnackBar, configSuccessSnackBar);
            }),
        );
    };
};

export const snackBarWarn = <T>(message?: string): MonoTypeOperatorFunction<T> => {
    return <T>(source: Observable<T>): Observable<T> => {
        return source.pipe(
            catchError(err => {
                const snackBar = RootInjector.get(MatSnackBar);
                snackBar.open(
                    message || err?.error?.meta?.message || messageWarnDefault,
                    actionTextSnackBar,
                    configWarnSnackBar,
                );
                throw err;
            }),
            /*
             * Нужно для component-store. Так как выкидывание ошибки завершает effect() и
             * повторный вызов effect не работает.
             * Поэтому оператор snackBarWarn надо использовать только после перехвата всех ошибок.
             * */
            catchError(() => EMPTY),
        );
    };
};

export const snackBarNotice = <T>(
    successMessage?: string,
    warnMessage?: string,
): MonoTypeOperatorFunction<T> => {
    return <T>(source: Observable<T>): Observable<T> => {
        return source.pipe(snackBarSuccess(successMessage), snackBarWarn(warnMessage));
    };
};
