import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogInterface } from './logging.interface';
import { FetcherService } from '@shared/services/fetcher/fetcher.service';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    constructor(public fetcherService: FetcherService) {}

    sentLogs(data: string[]): Observable<LogInterface> {
        return this.fetcherService.post<LogInterface>('/error', data);
    }
}
