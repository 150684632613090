import { ComponentStore } from '@ngrx/component-store';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { omit } from '@shared/utils/omit';

export class ComponentStorePersistent<T extends object> extends ComponentStore<T> {
    constructor(
        data: T,
        private name: string,
        private omitKeys: string[] = [],
    ) {
        super(ComponentStorePersistent.setValue<T>(name) || data);
    }

    private static setValue<T>(name: string): T {
        let value = {};
        try {
            value = JSON.parse(localStorage.getItem(name));
        } catch (_) {
            value = {};
        }
        return value as T;
    }

    private readonly saveStore = this.effect(() => {
        return this.state$.pipe(
            skip(1),
            tap(state => {
                if (this.omitKeys.length) {
                    state = omit(state, ...this.omitKeys);
                }
                localStorage.setItem(this.name, JSON.stringify(state));
            }),
            takeUntil(this.destroy$),
        );
    });
}
