<div class="data-range-wrapper" [formGroup]="form">
    <mat-form-field>
        <mat-label>{{ to?.label }}</mat-label>
        <mat-date-range-input [min]="to.min" [max]="to.max" [rangePicker]="picker!">
            <input
                (dateChange)="dateStartChange.next($event.value)"
                formControlName="start"
                matStartDate
                placeholder="От" />
            <input
                (dateChange)="dateEndChange.next($event.value)"
                formControlName="end"
                matEndDate
                placeholder="До" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        @if (to?.isReset) {
            <div class="mat-theme-overwrite__button-icon-nano">
                <button (click)="reset()" class="reset-btn" mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        }
    </mat-form-field>
</div>
