import { Observable, of } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';
import { delay, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppPreloadingStrategy implements PreloadingStrategy {
    preload(route: Route, load: () => Observable<any>): Observable<any> {
        return of(true).pipe(
            delay(60000),
            tap(() => load()),
        );
    }
}
