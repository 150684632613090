import { CustomerPerson } from '@shared/models';

export type Driver = Omit<CustomerPerson, 'status'> & {
    date_added: Date | string;
    status: {
        name: string;
        type: AdditionalDriverTicketStatus;
        color: string;
    };
};

export enum AdditionalDriverTicketStatus {
    /** Заявка по добавлению водителя в сессию в работе */
    IN_PROGRESS = 'IN_PROGRESS',

    /** Заявка отклонена (отменена) */
    REJECTED = 'REJECTED',

    /** Заявка выполнена. Пользователь добавлен в сессию */
    ACCEPTED = 'ACCEPTED',

    /** Пользователь удаляется из сессии */
    REMOVED = 'REMOVED',

    /** Пользователь удален из сессии */
    DELETED = 'DELETED',
}
