@if (to?.title) {
    <div class="title">
        {{ to.title }}
    </div>
}
<form [formGroup]="form" class="input-range-wrapper wrapper mat-theme-overwrite__input-full-min-width">
    <mat-form-field>
        <mat-label>{{ labelStart }}</mat-label>
        <input
            type="number"
            pattern="[0-9]+"
            [min]="to.min"
            [max]="to.max"
            formControlName="start"
            matInput />
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ labelEnd }}</mat-label>
        <input type="number" pattern="[0-9]+" [min]="to.min" [max]="to.max" formControlName="end" matInput />
    </mat-form-field>
</form>
