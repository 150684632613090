import { InjectionToken, Injector, Type } from '@angular/core';

/*
 * RootInjector is a good way of keeping track of the root injector so that you can
 * access the Injectables anywhere throughout your application.
 * https://nartc.netlify.app/blogs/root-injector/
 * */
export class RootInjector {
    private static rootInjector: Injector;

    static setInjector(injector: Injector) {
        if (!this.rootInjector) {
            this.rootInjector = injector;
        }
    }

    static get<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T): T {
        try {
            return this.rootInjector.get(token, notFoundValue);
        } catch (e) {
            console.error(
                `Error getting ${token} from RootInjector. This is likely due to RootInjector is undefined.
                 Please check RootInjector.rootInjector value.`,
            );
            return null;
        }
    }
}
