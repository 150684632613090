import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState } from './auth.state';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard {
    private authState = inject(AuthState);
    constructor(private router: Router) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authState.isAuth()) {
            this.router.navigate(['/']);
        }

        return !this.authState.isAuth();
    }
}

export const noAuthGuard = () => {
    const authState = inject(AuthState);
    const router = inject(Router);
    if (authState.isAuth()) {
        router.navigate(['/']);
    }

    return !authState.isAuth();
};
