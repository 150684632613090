import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

function isNullOrUndefined(item): boolean {
    return !item;
}

/**
 * Базовый сервис
 *
 * @export
 * @class Fetcher
 *
 * @todo кэширование (в observable сервисах) и обработку ошибок
 */
@Injectable({ providedIn: 'root' })
export class FetcherService {
    /**
     * Создает экземпляр объекта FetcherService
     *
     * @param {HttpClient} http
     * @memberof FetcherService
     */
    constructor(protected http: HttpClient) {}

    /**
     * Выполняет get-запрос
     *
     * @template T
     * @param {string} url
     * @param {HttpParams} [paramsObject=new HttpParams()]
     * @param {boolean} withEmpty
     * @returns {Observable<T>}
     * @memberof FetcherService
     */
    get<T>(
        url: string,
        paramsObject: HttpParams | { [param: string]: any } = new HttpParams(),
        withEmpty = false,
    ): Observable<T> {
        let params: HttpParams | { [param: string]: string | string[] };
        const headers = this.buildHeaders();

        if (paramsObject instanceof HttpParams) {
            params = new HttpParams();
            for (const key of paramsObject.keys()) {
                if (paramsObject.get(key) !== null) {
                    params = params.append(key, paramsObject.get(key));
                }
            }
        } else if (paramsObject) {
            try {
                params = {};
                Object.keys(paramsObject).forEach(key => {
                    if (!isNullOrUndefined(paramsObject[key]) || withEmpty) {
                        if (Array.isArray(paramsObject[key])) {
                            const arr = (paramsObject[key] as Array<any>).filter(
                                item => !isNullOrUndefined(item),
                            );
                            if (arr.length) {
                                params[key] = this.convertToArray(arr);
                            }
                        } else {
                            params[key] = paramsObject[key].toString();
                        }
                    }
                });
            } catch (err) {
                console.error(`Params\'es properties must have .toString() method! ${err}`);
            }
        }

        const options = {
            headers,
            params,
            observe: 'response' as const,
        };

        return this.http.get<T>(url, options).pipe(
            map(resp => {
                const result = resp.body;
                return result as T;
            }),
        );
    }

    private convertToArray<T>(array: T[]): string {
        return array.map(param => this.toString(param)).toString();
    }

    private toString<T>(param: T): string {
        return (
            (typeof param !== 'number' ? '"' : '') + param.toString() + (typeof param !== 'number' ? '"' : '')
        );
    }

    /**
     * Выполняет post-запрос
     *
     * @template T
     * @param {string} url
     * @param {object} data
     * @param options
     * @returns {Observable<T>}
     * @memberof FetcherService
     */
    post<T>(url: string, data: object, options?: object): Observable<T> {
        return this.http.post<T>(url, data, {
            headers: this.buildHeaders(),
            withCredentials: false,
            ...options,
        });
    }

    postTemp<T>(url: string, data: object): Observable<T> {
        return this.http.post<T>(url, data, {
            headers: this.buildHeaders(),
            withCredentials: false,
        });
    }

    /**
     * Выполняет put-запрос
     *
     * @template T
     * @param {string} url
     * @param {object} [data]
     * @returns {Observable<T>}
     * @memberof FetcherService
     */
    put<T>(url: string, data?: object): Observable<T> {
        return this.http.put<T>(url, data, {
            headers: this.buildHeaders(),
            withCredentials: false,
        });
    }

    /* Patch запрос */

    patch<T>(url: string, data?: object): Observable<T> {
        return this.http.patch<T>(url, data, {
            headers: this.buildHeaders(),
            withCredentials: false,
        });
    }

    /**
     * Выполняет delete-запрос
     *
     * @template T
     * @param {string} url
     * @returns {Observable<T>}
     * @memberof FetcherService
     */
    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(url, {
            headers: this.buildHeaders(),
            withCredentials: false,
        });
    }

    /**
     * Выполняет delete-запрос с телом запроса
     *
     * @template T
     * @param {string} url
     * @param {*} data
     * @returns {Observable<T>}
     * @memberof FetcherService
     */
    deleteWithBody<T>(url: string, data: any): Observable<T> {
        return this.http.request<T>('delete', url, {
            headers: this.buildHeaders({ ['Content-Type']: 'application/json' }),
            withCredentials: false,
            body: data,
        });
    }

    private buildHeaders(params?: { [key: string]: string }): HttpHeaders {
        // HttpHeaders - immutable поэтому .set идёт сразу при объявлении
        return new HttpHeaders(params);
    }
}
