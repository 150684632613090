import { VehicleItemDto } from '../vehicles';
import { ReverseMap } from '../reverse-map';

export enum EVehicleDeliveryStatus {
    Delivery = 'DELIVERY', // не отображать в таблице //Ожидает ПСО склад
    DeliveryNew = 'DELIVERY_NEW', // Новый (Доставка)
    DeliveryProblem = 'DELIVERY_PROBLEM', // Проблема (Доставка)
    WaitDeliveryToSklad = 'WAIT_DELIVERY_TO_SKLAD', // Ожидается отправка на склад
    GoToSklad = 'GO_TO_SKLAD', // Доставка на склад
    Sklad = 'SKLAD', // Готов к бронированию (склад)
    Transit = 'TRANSIT', //Новый, без доставки и предзаказа
    WaitDeliveryToClient = 'WAIT_DELIVERY_TO_CLIENT', // Ожидается доставка клиенту со склада
    WaitDeliveryToDealer = 'WAIT_DELIVERY_TO_DEALER', // Ожидается доставка в ДЦ со склада
    DeliveryToDealer = 'DELIVERY_TO_DEALER', // Доставка в ДЦ со склада
    DeliveredToDealer = 'DELIVERED_TO_DEALER', //  Доставлен в ДЦ со склада
    DeliveryToClient = 'DELIVERY_TO_CLIENT', // Доставка клиенту со склада
    Delivered = 'DELIVERED', //  Доставлен клиенту со склада
    DeliveredFromSklad = 'DELIVERED_FROM_SKLAD', // Доставка в ДЦ(доставлен)
    AwaitForCheckup = 'AWAIT_FOR_CHECKUP', //Ожидает проверки
    ReadyForRent = 'READY_FOR_RENT', //Доступен для бронирования
    CheckUpActive = 'CHECKUP_ACTIVE',
    Problem = 'PROBLEM',
    HourlyCharge = 'HOURLY_CHARGE',
    PostRent = 'POST_RENT',
    PreCheckup = 'PRE_CHECKUP',
    InsuranceHasExpired = 'INSURANCE_HAS_EXPIRED',
    InsuranceExpires = 'INSURANCE_EXPIRES',
    NeedForMaintenance = 'NEED_FOR_MAINTENANCE',
    Forced = 'FORCED',
    FakeVehicle = 'FAKE_VEHICLE',
    Tempbooked = 'TEMPBOOKED',
    New = 'NEW',
    WaitStartRent = 'WAIT_START_RENT',
    WaitPrepare = 'WAIT_PREPARE',
    OutOfService = 'OUT_OF_SERVICE',
    AvailablePreOrder = 'AVAILABLE_PRE_ORDER',
    RepairPaused = 'REPAIR_PAUSED',
    HasNewDamages = 'HAS_NEW_DAMAGES',
    Total = 'TOTAL',
    Sold = 'SOLD',
    CarTheft = 'CAR_THEFT',
    CarConfiscation = 'CAR_CONFISCATION',
    Confiscation = 'CONFISCATION',
    OverdueCheckup = 'OVERDUE_PREPARATION',
    DeliveryCheck = 'DELIVERY_CHECK',
    PaidPreOrder = 'PAID_PRE_ORDER',

    Rent = 'RENT',
    Rejected = 'REJECTED',
}

const reverseMap: ReverseMap<typeof EVehicleDeliveryStatus> = Object.entries(EVehicleDeliveryStatus).reduce(
    (rMap, [k, v]) => {
        rMap[v] = k;
        return rMap;
    },
    {} as any,
);

export type VehicleDeliveryStatus = keyof typeof reverseMap;

export enum EDeliveryExecutionStatus {
    Completed = 'COMPLETED',
    Canceled = 'CANCELED',
    Processing = 'PROCESSING',
}

const reverseMapExecutionStatus: ReverseMap<typeof EDeliveryExecutionStatus> = Object.entries(
    EDeliveryExecutionStatus,
).reduce((rMap, [k, v]) => {
    rMap[v] = k;
    return rMap;
}, {} as any);

export type DeliveryExecutionStatus = keyof typeof reverseMapExecutionStatus;

export interface VehiclesDeliveryModel {
    _id: string;
    _id_vehicle: string;
    vehicleRef: VehicleItemDto;
    delivery_status: VehicleDeliveryStatus;
    ready_date: string;
    store_date: string;
    booking_date: string;
    delivery_date: string;
    delivery_date_planned: string;
    delivery_date_fact: string;
    delivery_date_start: string;
    parking: string;
    is_accepted: boolean;
    sessionRef?: any;
    historyRef?: object[];
    execution_status: DeliveryExecutionStatus;
}

export interface VehiclesDeliveryDto extends VehiclesDeliveryModel, VehicleItemDto {
    damageRef: string[];
}

export interface VehiclesDeliveryStatusDto extends Partial<VehiclesDeliveryModel> {
    delivery_status: VehicleDeliveryStatus;
}
