// Merge into a locale object
const timeLocale = {
    placeholder: 'Выберите время',
    rangePlaceholder: ['Время начала', 'Время окончания'],
};

const locale = {
    lang: {
        placeholder: 'Выберите дату',
        yearPlaceholder: 'Выберите год',
        quarterPlaceholder: 'Выберите квартал',
        monthPlaceholder: 'Выберите месяц',
        weekPlaceholder: 'Выберите неделю',
        rangePlaceholder: ['Начальная дата', 'Конечная дата'],
        rangeYearPlaceholder: ['Начальный год', 'Год окончания'],
        rangeMonthPlaceholder: ['Начальный месяц', 'Конечный месяц'],
        rangeWeekPlaceholder: ['Начальная неделя', 'Конечная неделя'],
        locale: 'ru_RU',
        today: 'Сегодня',
        now: 'Сейчас',
        backToToday: 'Текущая дата',
        ok: 'Ok',
        clear: 'Очистить',
        month: 'Месяц',
        year: 'Год',
        timeSelect: 'Выбрать время',
        dateSelect: 'Выбрать дату',
        monthSelect: 'Выбрать месяц',
        yearSelect: 'Выбрать год',
        decadeSelect: 'Выбрать десятилетие',
        yearFormat: 'YYYY',
        dateFormat: 'DD.MM.YYYY',
        dayFormat: 'D',
        dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
        monthBeforeYear: true,
        previousMonth: 'Предыдущий месяц (PageUp)',
        nextMonth: 'Следующий месяц (PageDown)',
        previousYear: 'Предыдущий год (Control + left)',
        nextYear: 'Следующий год (Control + right)',
        previousDecade: 'Предыдущее десятилетие',
        nextDecade: 'Следущее десятилетие',
        previousCentury: 'Предыдущий век',
        nextCentury: 'Следующий век',
    },
    timePickerLocale: {
        ...timeLocale,
    },
};

// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

export default locale;
