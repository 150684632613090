import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputRangeComponent } from '../../fields/input-range/input-range.component';

@Component({
    selector: 'hml-mobility-input-range-type',
    templateUrl: './input-range-type.component.html',
    standalone: true,
    imports: [InputRangeComponent, ReactiveFormsModule],
})
export class InputRangeTypeComponent extends FieldType {}
