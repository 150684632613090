import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthState } from '@core/auth/auth.state';
import { environment } from '@environments/environment';
import { actionTextSnackBar, configWarnSnackBar } from '@shared/utils/rxjs/snack-bar';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const APP_URL = '/api/v2';

const setErrorMessage = (error, accessToken): string => {
    let message = 'Некорректный запрос.';
    if (error.status === 401 && accessToken !== null) {
        message = 'Сессия истекла';
    } else if (error.status === 504) {
        message = 'Сервис не отвечает. Повторите попытку.';
    } else if (error.status === 500) {
        message = 'Внутренняя ошибка сервера.';
    } else if (error.status === 400) {
        if (error.error?.code) {
            if (error.error.code === '23505') {
                message = 'Запись уже существует ';
            } else if (error.error.code === '23503') {
                message = 'Имеются зависимые данные. Операция отклонена.';
            } else {
                message = `Некорректный запрос. (Ошибка ${error.error.code})`;
            }
        }
    }

    return message;
};

export function appHttpInterceptor(
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    const authState = inject(AuthState);
    const snackBar = inject(MatSnackBar);
    const accessToken = authState.accessToken();

    let headers = request.headers
        .append('Authorization', 'Bearer ' + accessToken)
        .append('x-timezone', new Date().toString().match(/([-\+][0-9]+)\s/)[1]);

    if (!request.headers.has('X-Project')) {
        headers = headers.append('X-Project', 'crm_v2');
    }

    const externalUrl = new RegExp('^https://');
    const externalUrl2 = new RegExp(`^${environment.baseUrl}`);
    const customReq = request.clone({
        url:
            externalUrl.test(request.url) || externalUrl2.test(request.url) || request.url.match('assets/')
                ? request.url
                : APP_URL + request.url,
        headers,
    });

    return next(customReq).pipe(
        map(data => {
            return data;
        }),
        catchError(error => {
            if (error.status === 401 && accessToken !== null) {
                this.authState.authLogout();
            }
            snackBar.open(setErrorMessage(error, accessToken), actionTextSnackBar, configWarnSnackBar);
            throw error;
        }),
    );
}
