import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

export const getDataFromMetaResponse = (body: any) => {
    if (body && Object.prototype.hasOwnProperty.call(body, 'meta')) {
        return body.data;
    }
    return body;
};

export function metaResponseInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    return next(req).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                event = event.clone({ body: getDataFromMetaResponse(event.body) });
            }
            return event;
        }),
    );
}
